import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import React, { FC } from 'react';

import { DebitCardSmall } from './debit-card/DebitCardSmall';
import { InvestmentSmall } from './investment/InvestmentSmall';

interface InvestmentAndDebitCardSmallProps {
  onCtaClick: () => void;
}

export const InvestmentAndDebitCardSmall: FC<InvestmentAndDebitCardSmallProps> = (props) => (
  <ShowOn screens={[Screen.Sm, Screen.Md]}>
    <InvestmentSmall {...props} />
    <DebitCardSmall {...props} />
  </ShowOn>
);
