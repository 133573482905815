import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { colors } from 'component-library/styles/colors';
import { SectionLarge } from 'page-components/shared/section/SectionLarge';
import React, { FC } from 'react';

import { body, ctaText, heading } from './content';
import { Video } from './Video';
import { ExpertProps } from '.';
import { ButtonColor, PrimaryButton } from 'component-library/components/buttons/PrimaryButton';

export const ExpertLarge: FC<ExpertProps> = ({ onConsultationClick }) => (
  <SectionLarge
    backgroundColor={colors.transparent}
    image={<Video />}
    imageAlignment="left"
  >
    <ResponsiveHeading variant={2} marginBottom={24}>
      {heading}
    </ResponsiveHeading>
    <ResponsiveBody variant={1} marginBottom={48}>
      {body}
    </ResponsiveBody>
    <PrimaryButton
      buttonColor={ButtonColor.Peach}
      onClick={onConsultationClick}
      marginTop={32}
    >
      {ctaText}
    </PrimaryButton>
  </SectionLarge>
);
