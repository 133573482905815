import { graphql } from 'gatsby';
import { InvestmentAndDebitCardFragment as InvestmentAndDebitCardFragmentType } from 'graphqlTypes';
import React, { FC } from 'react';

import { InvestmentAndDebitCardLarge } from './InvestmentAndDebitCardLarge';
import { InvestmentAndDebitCardSmall } from './InvestmentAndDebitCardSmall';

export const InvestmentAndDebitCardFragment = graphql`
  fragment InvestmentAndDebitCard on Query {
    ...InvestmentAndDebitCardLarge
  }
`;

interface InvestmentAndDebitCardProps {
  data: InvestmentAndDebitCardFragmentType;
  onCtaClick: () => void;
}

export const InvestmentAndDebitCard: FC<InvestmentAndDebitCardProps> = (props) => (
  <>
    <InvestmentAndDebitCardLarge {...props} />
    <InvestmentAndDebitCardSmall onCtaClick={props.onCtaClick} />
  </>
);
