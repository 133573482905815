import React, { FC } from 'react';

import { ExpertLarge } from './ExpertLarge';
import { ExpertSmall } from './ExpertSmall';
import { BenefitHubChildProps } from 'pages/partners/benefit-hub';

export interface ExpertProps extends BenefitHubChildProps {}

export const Expert: FC<ExpertProps> = (props) => (
  <>
    <ExpertSmall {...props} />
    <ExpertLarge {...props} />
  </>
);
