import {
  ButtonColor,
  PrimaryButton,
  PrimaryButtonProps,
} from 'component-library/components/buttons/PrimaryButton';
import React, { FC } from 'react';

import { buttonCta } from './content';

interface ButtonCtaProps
  extends Omit<PrimaryButtonProps, 'buttonColor' | 'withCaret'> {}

export const ButtonCta: FC<ButtonCtaProps> = ({ onClick, ...rest }) => (
  <PrimaryButton
    buttonColor={ButtonColor.Peach}
    withCaret
    onClick={onClick}
    marginTop={32}
    {...rest}
  >
    {buttonCta}
  </PrimaryButton>
);