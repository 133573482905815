import { Flex } from 'component-library/components/layout/Flex';
import { Modal, ModalBody } from 'component-library/components/modals/Modal';
import { SubheadingText } from 'component-library/components/typography/Subheading';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { StyledFormLarge } from './StyledFormLarge';
import { StyledFormSmall } from './StyledFormSmall';

const Subheading = styled(SubheadingText)`
  margin: 24px 0;
`;

interface ConsultationModalProps {
  onClose: () => void;
}

export const MODAL_ID = 'consultation-modal';

export const ConsultationModal: FC<ConsultationModalProps> = ({ onClose }) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    retirementStatus: '',
  };

  return (
    <Modal
      modalId={MODAL_ID}
      wide
      fullHeight={false}
      backgroundColor={colors.teal}
      onClose={onClose}
    >
      <ModalBody>
        <Flex column justifyContent="center" alignItems="center">
          <StaticImage
            src="../images/retirable-logo.webp"
            alt="partnership logo"
            width={360}
          />
          <Subheading variant={1}>
            Claim your FREE Retirement Consultation & Plan
          </Subheading>
          <>
            <StyledFormSmall initialValues={initialValues} />
            <StyledFormLarge initialValues={initialValues} />
          </>
        </Flex>
      </ModalBody>
    </Modal>
  );
};
