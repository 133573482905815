import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';
import { ComeToRightPlaceProps } from '.';
import { bullets, heading } from './content';
import { StaticImage } from 'gatsby-plugin-image';
import { PanelLarge } from '../shared/PanelLarge';
import { getCurrentInterestRateDetails } from 'common/interest-rate-components/base';

export const ComeToRightPlaceLarge: FC<ComeToRightPlaceProps> = ({ data }) => {
  const { formattedApy } = getCurrentInterestRateDetails(data);
  
  return (
    <PanelLarge
      image={
        <StaticImage
          src="../images/couple.webp"
          alt="Happy retired couple"
        />
      }
      backgroundColor={colors.peach}
      imageAlignment="right"
      valueProps={[
        bullets.ongoingCare,
        bullets.financialPlanning,
        bullets.monthlyIncome,
        `${formattedApy} ${bullets.interestRate}`,
        bullets.fraudProtection,
      ]}
      heading={heading}
      spacing={48}
    />
  );
}
