import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { HeroProps } from '.';
import { body, ctaText, heading } from './content';
import { Video } from './Video';
import { ButtonColor, PrimaryButton } from 'component-library/components/buttons/PrimaryButton';

const HeroWrapper = styled(ShowOn)`
  margin-bottom: 48px;
`;

const HeroImage = styled.div`
  border-top: 1px solid ${colors.navyBlue};
  border-bottom: 1px solid ${colors.navyBlue};
`;

const HeroText = styled(Flex)`
  padding: 56px 20px;
`;

const ProductImages = styled.div`
  padding: 46px;
  margin-top: -40%;
`;

export const HeroSmall: FC<HeroProps> = ({ onConsultationClick }) => (
  <HeroWrapper screens={[Screen.Sm, Screen.Md]}>
    <Flex column>
      <HeroText column justifyContent="center">
        <Heading marginBottom={16} variant={4}>
          {heading}
        </Heading>
        <Body marginBottom={48} variant={3}>
          {body}
        </Body>
        <PrimaryButton
          buttonColor={ButtonColor.Peach}
          onClick={onConsultationClick}
          marginTop={32}
        >
          {ctaText}
        </PrimaryButton>
      </HeroText>
      <HeroImage>
        <Video />
      </HeroImage>
      <ProductImages>
        <Flex marginBottom={24}>
          <StaticImage
            src="../images/upcoming-plan-review.webp"
            alt="upcoming-plan-review"
            width={192}
          />
        </Flex>
        <Flex justifyContent="flex-end">
          <StaticImage
            src="../images/next-paycheck.webp"
            alt="next-pacheck"
            width={226}
          />
        </Flex>
      </ProductImages>
    </Flex>
  </HeroWrapper>
);
