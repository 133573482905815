import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Subheading } from 'component-library/components/typography/Subheading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { InvestmentAndDebitCardWrapper } from '../shared';
import { body, heading } from './content';
import { ButtonCta } from './shared';

const TextAndCta = styled.div`
  padding: 0 20px 60px;
`;

interface DebitCardSmallProps {
  onCtaClick: () => void;
}

export const DebitCardSmall:FC<DebitCardSmallProps> = ({ onCtaClick }) => (
  <InvestmentAndDebitCardWrapper backgroundColor={colors.navyBlue}>
    <ShowOn screens={[Screen.Sm]}>
      <StaticImage
        src="../../images/debit-card-small.webp"
        alt="investing-timeline"
      />
    </ShowOn>
    <ShowOn screens={[Screen.Md]}>
      <Flex justifyContent="center">
        <StaticImage
          src="../../images/debit-card-large.webp"
          alt="investing-timeline"
          width={450}
        />
      </Flex>
    </ShowOn>
    <TextAndCta>
      <Subheading marginBottom={16} variant={1} color={colors.white}>
        {heading}
      </Subheading>
      <Body marginBottom={48} variant={3} color={colors.white}>
        {body}
      </Body>
      <ButtonCta onClick={onCtaClick} />
    </TextAndCta>
  </InvestmentAndDebitCardWrapper>
);
