import React, { FC } from 'react';
import { LoveItOrItsFreeProps } from '.';
import { body, ctaText, heading } from './content';
import { PanelLarge } from '../shared/PanelLarge';
import { StaticImage } from 'gatsby-plugin-image';

export const LoveItOrItsFreeLarge: FC<LoveItOrItsFreeProps> = ({ onConsultationClick }) => (
  <PanelLarge
    image={
      <StaticImage
        src="../images/charts.webp"
        alt="Investment charts"
      />
    }
    imageAlignment="right"
    body={body}
    heading={heading}
    spacing={48}
    buttonText={ctaText}
    onButtonClick={onConsultationClick}
  />
);
