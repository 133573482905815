import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { StyledCheckmark } from 'page-components/index/plan/shared';
import React, { FC } from 'react';
import styled from 'styled-components';

interface PanelSmallProps {
  image: React.ReactNode;
  imageAlignment?: 'above' | 'below';
  backgroundColor?: string;
  heading: string;
  body?: string;
  valueProps?: string[];
  withImagePadding?: boolean;
  buttonText?: string;
  onButtonClick?: () => void;
}

const PanelSmallWrapper = styled(Flex)<
  Pick<PanelSmallProps, 'backgroundColor'>
>`
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'inherit') };
  border-radius: 24px;
  margin-bottom: 32px;
`;

const Image = styled.div<Pick<PanelSmallProps, 'withImagePadding'>>`
  padding: ${({ withImagePadding = false }) =>
    withImagePadding ? '0 20px 32px' : '0'};

  max-width: 400px;
  margin: 24px auto 0;
`;

const Text = styled.div`
  padding: 24px 20px 24px;
`;

export const PanelSmall: FC<PanelSmallProps> = ({
  backgroundColor,
  image,
  imageAlignment,
  heading,
  body,
  withImagePadding,
  valueProps,
  buttonText,
  onButtonClick,
}) => (
  <ShowOn screens={[Screen.Sm, Screen.Md]}>
    <PanelSmallWrapper column backgroundColor={backgroundColor}>
      {imageAlignment === 'above' && (
        <Image withImagePadding={withImagePadding}>{image}</Image>
      )}
      <Text>
        <Heading variant={4} marginTop={32}>
          {heading}
        </Heading>
        {imageAlignment === 'below' && (
          <Image withImagePadding={withImagePadding}>{image}</Image>
        )}
        {body && (
          <Body variant={4} marginTop={24}>
            {body}
          </Body>
        )}
        {valueProps && (
          <Flex column marginTop={24}>
            {valueProps.map((valueProp, idx) => (
              <Flex key={idx} marginBottom={16}>
                <StyledCheckmark />
                <Body variant={3}>{valueProp}</Body>
              </Flex>
            ))}
          </Flex>
        )}
        {buttonText && (
          <PrimaryButton
            small
            alignment="center"
            buttonColor={ButtonColor.Peach}
            onClick={onButtonClick}
            marginTop={32}
          >
            {buttonText}
          </PrimaryButton>
        )}
      </Text>
    </PanelSmallWrapper>
  </ShowOn>
);
