import { Body } from 'component-library/components/typography/Body';
import { Subheading } from 'component-library/components/typography/Subheading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { InvestmentAndDebitCardWrapper } from '../shared';
import { body, heading } from './content';
import { ButtonCta } from './shared';

const TextAndCta = styled.div`
  padding: 40px 20px 60px;
`;

interface InvestmentSmallProps {
  onCtaClick: () => void;
}

export const InvestmentSmall:FC<InvestmentSmallProps> = ({ onCtaClick }) => (
  <InvestmentAndDebitCardWrapper backgroundColor={colors.teal}>
    <ShowOn screens={[Screen.Sm]}>
      <StaticImage
        src="../../images/investment-small.webp"
        alt="investing-timeline"
      />
    </ShowOn>
    <ShowOn screens={[Screen.Md]}>
      <StaticImage
        src="../../images/investment-large.webp"
        alt="investing-timeline"
      />
    </ShowOn>
    <TextAndCta>
      <Subheading marginBottom={16} variant={1}>
        {heading}
      </Subheading>
      <Body marginBottom={48} variant={3}>
        {body}
      </Body>
      <ButtonCta onClick={onCtaClick} />
    </TextAndCta>
  </InvestmentAndDebitCardWrapper>
);
