import React, { FC } from 'react';

import { ComeToRightPlaceLarge } from './ComeToRightPlaceLarge';
import { ComeToRightPlaceSmall } from './ComeToRightPlaceSmall';
import { GetCurrentInterestRateDetailsFragment } from 'graphqlTypes';

export interface ComeToRightPlaceProps {
  data: GetCurrentInterestRateDetailsFragment;
}

export const ComeToRightPlace: FC<ComeToRightPlaceProps> = (props) => (
    <>
      <ComeToRightPlaceSmall {...props} />
      <ComeToRightPlaceLarge {...props} />
    </>
);
