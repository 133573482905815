import { Flex } from 'component-library/components/layout/Flex';
import styled from 'styled-components';

interface InvestmentAndDebitCardWrapperProps {
  backgroundColor: string;
}

export const InvestmentAndDebitCardWrapper = styled(Flex).attrs({
  column: true,
})<InvestmentAndDebitCardWrapperProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 24px;
  margin: 32px 0;
  flex: 1;
  overflow: hidden;
`;
