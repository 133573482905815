import { Flex } from 'component-library/components/layout/Flex';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { HeroProps } from '.';
import { body, ctaText, heading } from './content';
import { Video } from './Video';
import { ButtonColor, PrimaryButton } from 'component-library/components/buttons/PrimaryButton';

const HeroWrapper = styled(ShowOn)`
  margin-bottom: 48px;
`;

const HeroText = styled(Flex)`
  flex-basis: 58%;
  max-width: 722px;
  margin: auto;
  padding: 40px;
`;

const HeroImage = styled(Flex)`
  position: relative;
  flex-basis: 42%;
  border-right: 1px solid ${colors.navyBlue};
`;

const PlanReview = styled.div`
  position: absolute;
  bottom: 4%;
  left: 14%;
  width: 28%;
`;

const NextPaycheck = styled.div`
  position: absolute;
  bottom: 10%;
  right: 8%;
  width: 34%;
`;

export const HeroLarge: FC<HeroProps> = ({ onConsultationClick }) => (
  <HeroWrapper screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <Flex>
      <HeroImage>
        <Video />
        <PlanReview>
          <StaticImage
            src="../images/upcoming-plan-review.webp"
            alt="upcoming-plan-review"
          />
        </PlanReview>
        <NextPaycheck>
          <StaticImage src="../images/next-paycheck.webp" alt="next-pacheck" />
        </NextPaycheck>
      </HeroImage>
      <HeroText column justifyContent="center">
        <ResponsiveHeading variant={2} marginBottom={24}>
          {heading}
        </ResponsiveHeading>
        <ResponsiveBody variant={1} marginBottom={24}>
          {body}
        </ResponsiveBody>
        <PrimaryButton
          buttonColor={ButtonColor.Peach}
          onClick={onConsultationClick}
          marginTop={32}
        >
          {ctaText}
        </PrimaryButton>
      </HeroText>
    </Flex>
  </HeroWrapper>
);
