import { StaticImage } from 'gatsby-plugin-image';
import video from 'page-components/index/videos/expert.mp4';
import React from 'react';
import styled from 'styled-components';

const VideoWrapper = styled.div`
  position: relative;
`;

const VideoSizer = styled.div`
  overflow: hidden;
  position: absolute;
  top: 7.7%;
  left: 11.5%;
`;

const StyledVideo = styled.video`
  border-radius: 6% 6% 0 0;
  width: 78.9%;
`;

const ImageWrapper = styled.div`
  z-index: 1;
  position: relative;
`;

export const Video = () => (
  <VideoWrapper>
    <ImageWrapper>
      <StaticImage src="../images/expert.webp" alt="expert-providing-advice" />
    </ImageWrapper>
    <VideoSizer>
      <StyledVideo autoPlay loop muted playsInline>
        <source src={video} type="video/mp4" />
      </StyledVideo>
    </VideoSizer>
  </VideoWrapper>
);
