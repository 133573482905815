import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { colors } from 'component-library/styles/colors';
import { SectionSmall } from 'page-components/shared/section/SectionSmall';
import React, { FC } from 'react';

import { body, ctaText, heading } from './content';
import { Video } from './Video';
import { ExpertProps } from '.';
import { ButtonColor, PrimaryButton } from 'component-library/components/buttons/PrimaryButton';

export const ExpertSmall: FC<ExpertProps> = ({ onConsultationClick }) => (
  <SectionSmall backgroundColor={colors.teal} image={<Video />}>
    <Heading marginBottom={16} variant={4}>
      {heading}
    </Heading>
    <Body marginBottom={40} variant={3}>
      {body}
    </Body>
    <PrimaryButton
      buttonColor={ButtonColor.Peach}
      onClick={onConsultationClick}
      marginTop={32}
    >
      {ctaText}
    </PrimaryButton>
  </SectionSmall>
);
