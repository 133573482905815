import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';
import { LoveItOrItsFreeProps } from '.';
import { body, ctaText, heading } from './content';
import { PanelSmall } from '../shared/PanelSmall';
import { StaticImage } from 'gatsby-plugin-image';

export const LoveItOrItsFreeSmall: FC<LoveItOrItsFreeProps> = ({ onConsultationClick }) => (
  <PanelSmall
    image={
      <StaticImage
        src="../images/charts.webp"
        alt="Investment charts"
      />
    }
    backgroundColor={colors.peach}
    imageAlignment="above"
    heading={heading}
    body={body}
    buttonText={ctaText}
    onButtonClick={onConsultationClick}
  />
);
