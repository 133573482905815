import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import {
  FormikPhoneInput,
  FormikSelectInput,
  FormikTextInput,
} from 'component-library/components/form';
import { Flex } from 'component-library/components/layout/Flex';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { Field, Form, Formik } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components';

import {
  ConsultationFormData,
  ConsultationFormSchema,
  retirementStatusOptions,
  submitForm,
} from './formUtils';
import { Body } from 'component-library/components/typography/Body';

const StyledForm = styled(Form)`
  width: 100%;
`;

const StyledFormikSelectField = styled(Field)`
  z-index: 10;
  margin-bottom: 36px;
`;

interface StyledFormSmallProps {
  initialValues: ConsultationFormData;
}

export const StyledFormSmall: FC<StyledFormSmallProps> = ({
  initialValues,
}) => (
  <ShowOn screens={[Screen.Sm, Screen.Md, Screen.Lg]}>
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={ConsultationFormSchema}
    >
      {({ isSubmitting }) => (
        <StyledForm>
          <Flex column>
            <Field
              name="firstName"
              placeholder="First Name"
              component={FormikTextInput}
              inputWidth={4}
              disabled={isSubmitting}
            />
            <Field
              name="lastName"
              placeholder="Last Name"
              component={FormikTextInput}
              inputWidth={4}
              disabled={isSubmitting}
            />
            <Field
              name="email"
              inputMode="email"
              placeholder="Email"
              component={FormikTextInput}
              inputWidth={4}
              disabled={isSubmitting}
            />
            <Field
              name="phone"
              placeholder="Phone"
              component={FormikPhoneInput}
              inputWidth={4}
              disabled={isSubmitting}
            />
            <Flex marginBottom={24}>
              <Body variant={2}>
                Are you currently retired OR retiring in the next 5 years?
              </Body>
            </Flex>
            <StyledFormikSelectField
              name="retirementStatus"
              component={FormikSelectInput}
              width='100%'
              height='64px'
              options={retirementStatusOptions}
              disabled={isSubmitting}
            />
            <PrimaryButton
              fullWidth
              buttonColor={ButtonColor.Peach}
              disabled={isSubmitting}
              alignment="center"
            >
              Submit
            </PrimaryButton>
          </Flex>
        </StyledForm>
      )}
    </Formik>
  </ShowOn>
);
