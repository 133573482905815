import React, { FC } from 'react';

import { HeroLarge } from './HeroLarge';
import { HeroSmall } from './HeroSmall';
import { BenefitHubChildProps } from 'pages/partners/benefit-hub';

export interface HeroProps extends BenefitHubChildProps {}

export const Hero: FC<HeroProps> = (props) => (
  <>
    <HeroSmall {...props} />
    <HeroLarge {...props} />
  </>
);
