import React, { FC } from 'react';

import { StepsLarge } from './StepsLarge';
import { StepsSmall } from './StepsSmall';
import { BenefitHubChildProps } from 'pages/partners/benefit-hub';

interface StepsProps extends BenefitHubChildProps {}

export const Steps: FC<StepsProps> = (props) => (
  <>
    <StepsSmall {...props} />
    <StepsLarge {...props} />
  </>
);
