import { Heading } from 'component-library/components/typography/Heading';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { InvestmentAndDebitCardWrapper } from '../shared';
import { body, heading } from './content';
import { ButtonCta } from './shared';

const TextAndCta = styled.div`
  padding: 40px;
  max-width: 708px;
  margin: 0 auto;
  margin-top: auto;
`;

interface InvestmentLargeProps {
  onCtaClick: () => void;
}

export const InvestmentLarge: FC<InvestmentLargeProps> = ({ onCtaClick }) => (
  <InvestmentAndDebitCardWrapper backgroundColor={colors.teal}>
    <StaticImage
      src="../../images/investment-large.webp"
      alt="investing-timeline"
    />
    <TextAndCta>
      <Heading marginBottom={40} variant={4}>
        {heading}
      </Heading>
      <ResponsiveBody marginBottom={48} variant={2}>
        {body}
      </ResponsiveBody>
      <ButtonCta onClick={onCtaClick} />
    </TextAndCta>
  </InvestmentAndDebitCardWrapper>
);
