import video from 'page-components/index/videos/hero.mp4';
import React from 'react';
import styled from 'styled-components';

const StyledVideo = styled.video`
  margin-left: -78%;
  width: 192%;
  max-width: unset;
`;

const VideoWrapper = styled.div`
  overflow: hidden;
`;

export const Video = () => (
  <VideoWrapper>
    <StyledVideo autoPlay loop playsInline muted>
      <source src={video} type="video/mp4" />
    </StyledVideo>
  </VideoWrapper>
);
