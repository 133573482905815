import { Grid } from 'common/layouts/grid/Grid';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { graphql } from 'gatsby';
import { InvestmentAndDebitCardLargeFragment as InvestmentAndDebitCardLargeFragmentType } from 'graphqlTypes';
import React, { FC } from 'react';
import styled from 'styled-components';

import { DebitCardLarge } from './debit-card/DebitCardLarge';
import { InvestmentLarge } from './investment/InvestmentLarge';

export const InvestmentAndDebitCardLargeFragment = graphql`
  fragment InvestmentAndDebitCardLarge on Query {
    ...DebitCardLarge
  }
`;

const StyledGrid = styled(Grid)`
  margin: 40px;
  gap: 40px;
`;

interface InvestmentAndDebitCardLargeProps {
  data: InvestmentAndDebitCardLargeFragmentType;
  onCtaClick: () => void;
}

export const InvestmentAndDebitCardLarge: FC<InvestmentAndDebitCardLargeProps> = ({
  data,
  onCtaClick,
}) => (
  <ShowOn screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <StyledGrid cols={2}>
      <InvestmentLarge onCtaClick={onCtaClick} />
      <DebitCardLarge onCtaClick={onCtaClick} data={data} />
    </StyledGrid>
  </ShowOn>
);
