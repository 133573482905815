import Layout from 'common/layouts/Layout';
import { TrustPilot } from 'common/trustpilot/TrustPilot';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { SEO } from 'components/SEO';
import { graphql } from 'gatsby';
import { BenefitHubQuery } from 'graphqlTypes';
import { ComeToRightPlace } from 'page-components/partnerships/deprecated/benefit-hub/come-to-right-place';
import { ConsultationModal } from 'page-components/partnerships/deprecated/benefit-hub/consultation-form/ConsultationModal';
import { Expert } from 'page-components/partnerships/deprecated/benefit-hub/expert';
import { Hero } from 'page-components/partnerships/deprecated/benefit-hub/hero';
import { InvestmentAndDebitCard } from 'page-components/partnerships/deprecated/benefit-hub/investment-and-debit-card';
import { LoveItOrItsFree } from 'page-components/partnerships/deprecated/benefit-hub/love-it-or-its-free';
import { RetirableProcess } from 'page-components/partnerships/deprecated/benefit-hub/retirable-process';
import { Steps } from 'page-components/partnerships/deprecated/benefit-hub/steps';
import { promoterVerificationComplianceFooterFor } from 'page-components/partnerships/shared/content';
import { Footer } from 'page-components/shared/footer';
import { FOOTNOTE_REFERENCES } from 'page-components/shared/footer/utils';
import React, { FC } from 'react';

interface BenefitHubProps {
  data: BenefitHubQuery;
}

export interface BenefitHubChildProps {
  onConsultationClick: () => void;
}

const BenefitHub: FC<BenefitHubProps> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleConsultationClick = () => {
    setIsModalOpen(true);
  };

  const handleConsultationClose = () => {
    setIsModalOpen(false);
  };

  const childProps = {
    onConsultationClick: handleConsultationClick,
  };

  return (
    <Layout>
      <Navigation onPrimaryCtaClick={handleConsultationClick} />
      <PageWrapper
        background="linear-gradient(
          180deg,
          #dbf0e7 25%,
          #ffe8d8 50%,
          #dcf1e8 75%,
          rgba(220, 241, 232, 0.2) 90%,
          #dbf0e7 100%
        )"
      >
        {isModalOpen && <ConsultationModal onClose={handleConsultationClose} />}
        <Hero {...childProps} />
        <ComeToRightPlace data={data} />
        <LoveItOrItsFree {...childProps} />
        <Expert {...childProps} />
        <RetirableProcess {...childProps} />
        <InvestmentAndDebitCard
          data={data}
          onCtaClick={handleConsultationClick}
        />
        <TrustPilot
          template="fullSizeCarousel"
          heading="We've helped more than 50,000 people just like you."
        />
        <Steps {...childProps} />
        <Footer
          additionalFootnotes={[
            promoterVerificationComplianceFooterFor('BenefitHub'),
            <>
              {FOOTNOTE_REFERENCES.dagger} Simply signup to become a Retirable customer and if you're not satisfied
              with your Retirable investment account, you can activate the satisfaction guarantee within 60 days of
              your portfolio being invested. We'll refund all advisory fees paid during this period. Please note that
              this guarantee does not protect you against market losses.
            </>,
          ]}
        />
      </PageWrapper>
    </Layout>
  );
};

export default BenefitHub;

export const query = graphql`
  query BenefitHub {
    ...GetCurrentInterestRateDetails
  }
`;

export const Head = () => (
  <SEO
    title="BenefitHub + Retirable"
    description="Learn more about how BenefitHub and Retirable are working together to help you have peace of mind in retirement."
  />
);
