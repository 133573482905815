import { ApyBadge } from 'common/interest-rate-components/ApyBadge';
import { Heading } from 'component-library/components/typography/Heading';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { colors } from 'component-library/styles/colors';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { DebitCardLargeFragment as DebitCardLargeFragmentType } from 'graphqlTypes';
import React, { FC } from 'react';
import { device } from 'shared/theme';
import styled from 'styled-components';

import { InvestmentAndDebitCardWrapper } from '../shared';
import { body, heading } from './content';
import { ButtonCta } from './shared';

export const DebitCardLargeFragment = graphql`
  fragment DebitCardLarge on Query {
    ...ApyBadge
  }
`;

const TextAndCta = styled.div`
  padding: 0 40px 40px;
  max-width: 708px;
  margin: 0 auto;
  margin-top: auto;
`;

const ImageWrapper = styled.div`
  margin-top: -8%;
  padding-left: 16%;
`;

const StyledApyBadgeWrapper = styled.div`
  display: inline-flex;
  position: relative;
  padding-left: 8%;
  z-index: 2;

  top: 20%;

  ${`@media ${device.md}`} {
    top: 20%;
  }

  ${`@media ${device.lg}`} {
    top: 20%;
  }

  ${`@media ${device.xl}`} {
    top: 25%;
  }

  ${`@media ${device.xxl}`} {
    top: 30%;
  }
  ${`@media ${device.xxxl}`} {
    top: 40%;
  }
`;

interface DebitCardLargeProps {
  data: DebitCardLargeFragmentType;
  onCtaClick: () => void;
}

export const DebitCardLarge: FC<DebitCardLargeProps> = ({ data, onCtaClick }) => (
  <InvestmentAndDebitCardWrapper backgroundColor={colors.navyBlue}>
    <StyledApyBadgeWrapper>
      <ApyBadge data={data} hideBoxShadow />
    </StyledApyBadgeWrapper>
    <ImageWrapper>
      <StaticImage
        src="../../images/debit-card-large.webp"
        alt="spending-card"
      />
    </ImageWrapper>
    <TextAndCta>
      <Heading marginBottom={40} variant={4} color={colors.white}>
        {heading}
      </Heading>
      <ResponsiveBody marginBottom={48} variant={2} color={colors.white}>
        {body}
      </ResponsiveBody>
      <ButtonCta onClick={onCtaClick} />
    </TextAndCta>
  </InvestmentAndDebitCardWrapper>
);
