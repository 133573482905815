import React, { FC } from 'react';

import { LoveItOrItsFreeSmall } from './LoveItOrItsFreeSmall';
import { LoveItOrItsFreeLarge } from './LoveItOrItsFreeLarge';
import { BenefitHubChildProps } from 'pages/partners/benefit-hub';

export interface LoveItOrItsFreeProps extends BenefitHubChildProps {}

export const LoveItOrItsFree: FC<LoveItOrItsFreeProps> = (props) => (
    <>
      <LoveItOrItsFreeSmall {...props} />
      <LoveItOrItsFreeLarge {...props} />
    </>
);
