import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { StyledCheckmark } from 'page-components/index/plan/shared';
import React, { FC } from 'react';
import styled from 'styled-components';

interface PanelLargeProps {
  image: React.ReactNode;
  imageAlignment: 'left' | 'right';
  body?: string | React.ReactNode;
  backgroundColor?: string;
  heading: string;
  spacing?: number;
  textMaxWidth?: number;
  valueProps?: string[];
  buttonText?: string;
  onButtonClick?: () => void;
}

const PanelLargeWrapper = styled(Flex)<
  Pick<PanelLargeProps, 'spacing' | 'imageAlignment' | 'backgroundColor'>
>`
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'inherit') };
  border-radius: 24px;
  max-width: 1488px;
  margin: ${({ spacing }) => `0 auto ${spacing}px`};
  padding: 0 40px;
  flex-direction: ${({ imageAlignment }) =>
    imageAlignment === 'left' ? 'row' : 'row-reverse'};
`;

const Image = styled.div<Pick<PanelLargeProps, 'imageAlignment'>>`
  flex-basis: 50%;
  padding-right: ${({ imageAlignment }) =>
    imageAlignment === 'left' ? '20px' : '0'};
  padding-left: ${({ imageAlignment }) =>
    imageAlignment === 'left' ? '0' : '20px'};
`;

const Text = styled(Flex)<Pick<PanelLargeProps, 'imageAlignment'>>`
  flex-basis: 50%;
  padding-left: ${({ imageAlignment }) =>
    imageAlignment === 'right' ? '40px' : '60px'};
  align-items: center;
`;

const TextWrapper = styled.div<Pick<PanelLargeProps, 'textMaxWidth'>>`
  position: relative;
  max-width: ${({ textMaxWidth = 597 }) => `${textMaxWidth}px`};
`;

export const PanelLarge: FC<PanelLargeProps> = ({
  imageAlignment,
  image,
  body,
  backgroundColor,
  heading,
  spacing,
  textMaxWidth,
  valueProps,
  buttonText,
  onButtonClick,
}) => (
  <ShowOn screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <PanelLargeWrapper spacing={spacing} imageAlignment={imageAlignment} backgroundColor={backgroundColor}>
      <Image imageAlignment={imageAlignment}>{image}</Image>
      <Text imageAlignment={imageAlignment}>
        <TextWrapper textMaxWidth={textMaxWidth}>
          <ResponsiveHeading variant={2} marginBottom={32}>
            {heading}
          </ResponsiveHeading>
          {body && (
            <ResponsiveBody variant={1} marginBottom={24}>
              {body}
            </ResponsiveBody>
          )}
          {valueProps && (
            <Flex column>
              {valueProps.map((valueProp, idx) => (
                <Flex key={idx} marginBottom={16}>
                  <StyledCheckmark />
                  <Body variant={3}>{valueProp}</Body>
                </Flex>
              ))}
            </Flex>
          )}
          {buttonText && (
            <PrimaryButton
              buttonColor={ButtonColor.Peach}
              onClick={onButtonClick}
              marginTop={32}
            >
              {buttonText}
            </PrimaryButton>
          )}
        </TextWrapper>
      </Text>
    </PanelLargeWrapper>
  </ShowOn>
);
