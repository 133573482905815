import axios from 'axios';
import {
  emailValidation,
  firstNameValidation,
  phoneValidation,
} from 'component-library/components/form/fields';
import { track } from 'utils/analytics';
import * as yup from 'yup';

import { MODAL_ID } from './ConsultationModal';

export interface ConsultationFormData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  retirementStatus: string;
}

export interface ConsultationFormSubmitPayload extends ConsultationFormData {
  source: string;
  medium: string;
  term: string;
  campaign: string;
}

export const retirementStatusOptions = [
  { value: 'retired', label: 'Yes, I\'m retired' },
  { value: 'retiring_soon', label: 'Yes, retiring in the next 5 years' },
  { value: 'not_retired', label: 'No' },
  { value: 'never_retire', label: 'I\'m worried I may never retire' },
];

export const ConsultationFormSchema = yup.object().shape({
  firstName: firstNameValidation,
  lastName: yup
    .string()
    .required('Last name is required')
    .typeError('Please enter your last name'),
  phone: phoneValidation,
  email: emailValidation,
  retirementStatus: yup.string().required('Retirement status is required'),
});

export const submitForm = async (data: ConsultationFormData) => {
  const payload: ConsultationFormSubmitPayload = {
    ...data,
    source: 'medicare',
    medium: 'landing page',
    term: 'benefit-hub',
    campaign: 'benefit-hub',
  };

  const { retirementStatus } = data;

  track('Modal Form Submitted', { modalId: MODAL_ID, retirementStatus });

  await axios.request({
    method: 'post',
    url: '/api/createMedicareLead',
    data: payload,
  });
  onSuccess(payload);
};

const onSuccess = (data: ConsultationFormSubmitPayload) => {
  const params = new URLSearchParams({
    first_name: data.firstName,
    last_name: data.lastName,
    phone_number: data.phone,
    email: data.email,
    medium: data.medium,
    campaign: data.campaign,
    term: data.term,
  });
  window.location.href = `${process.env.GATSBY_HOWDY_BASE_URL}/partners/${
    data.campaign
  }/appointments/new?${params.toString()}`;
};
